import React, { useContext, useEffect, useState } from "react";
import Pricing_Plans from "./components/Pricing_Plans";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Foot_ from "../../components/footer/Foot_";
import { IconSvg, ThemeContext, device, Main_Loader } from "monica-alexandria";
import { H1, H3, H6, P } from "../../library/Presentation";
import Extra_Services from "./components/Extra_Services";
import { getProductswithCategory } from "../../services/api/Products";
import Play_Video from "./components/Play_Video";
import Landing_Header from "./components/Landing_Header";

const Landing = styled.div``;

const LandingTop = styled.div`
  position: relative;
  background: linear-gradient(
    110deg,
    var(--main) -1%,
    var(--focus) 19.35%,
    #cea43f 60.75%
  );
  padding-bottom: 16rem;
`;

const LandingHero = styled.div`
  display: grid;
  grid-template-columns: 5fr 3fr;
  grid-gap: 2rem;
  height: 75rem;
  align-items: center;
  padding: 0 4rem;
  position: relative;
  overflow: hidden;

  @media ${device.md} {
    height: 90rem;
  }

  @media ${device.sm} {
    padding: 0 2rem;
    grid-template-columns: 1fr;
    align-items: flex-start;
    padding-top: 4rem;
    grid-gap: 0;
  }
`;

const LandingHeroText = styled.div`
  margin-bottom: 10rem;

  p{
    color: var(--white);
    
    max-width: 40rem;
    margin: 0 auto;
    /* margin-bottom: 2rem; */
  }

  h6{
    color: var(--white);
    max-width: 40rem;
    margin: 0 auto;
    margin-top: 1rem;
  }

  h1 {
    color: var(--white);
    font-size: 3rem;
    margin: 0 auto;
    margin-bottom: 2rem;
    max-width: 40rem;
  }

  h3 {
    font-size: 3.1rem;
    color: var(--white);
    letter-spacing: 1px;
  }

  @media ${device.md} {
    h1 {
      font-size: 4rem;
    }

    h3 {
      font-size: 2rem;
    }
  }

  @media ${device.sm} {
    p,
    h6,
    h1,
    h3 {
      /* text-align: center; */
    }
  }
`;

const LandingHeroImg = styled.div`
  position: relative;
  width: 100%;
`;

const LandingHeroVan = styled.img`
  position: absolute;
  width: 45rem;
  bottom: -35rem;
  left: 0rem;

  @media ${device.sm} {
    width: 30rem;
    left: 50%;
    bottom: -20rem;
    transform: translateX(-50%);
  }
`;

const LandingHeroSign = styled.img`
  position: absolute;
  width: 35rem;
  bottom: -10rem;
  left: 7rem;

  @media ${device.sm} {
    width: 30rem;
    left: 50%;
    bottom: 0rem;
    transform: translateX(-50%);
  }
`;

const LandingHeroSplitter = styled.img`
  position: absolute;
  bottom: 0rem;
  left: 0;
  width: 100%;
`;

export default function LANDING() {
  const [state, setState] = useState();

  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();

  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  const get_products_with_category = () => {
    getProductswithCategory({category: "bond", property: "vandador"})
      .then((res) => {
        setPlans(res?.filter((plan) => {return plan?.interval==="month"}));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    get_products_with_category();
  }, []);

  if (!plans?.length) return <Main_Loader />
  return (
    <Landing>
      <Landing_Header />
      {/* <LandingTop>
        <LandingHero>
          <LandingHeroText>
            <H1>{t("We live in an era where branding is easier, but also more necessary, than ever.")} </H1>
            <P>{t("For small and medium-sized businesses, this requires time, specialized knowledge, and outsourcing to an expert is expensive.")} </P>
            <P>{t("We want to change this by providing a solution to this need in the best, most economical, but also most effective way!")} </P>
            <P>{t("Welcome to the new digital age, now it's your time to take the next step!")} </P>
            <H6>{t("Alexios")}</H6>
          </LandingHeroText>
          <LandingHeroImg>
            <LandingHeroVan src="https://genius1071.friktoriaservers.net/doitforme/morning/app/vandador/landing/Vandoros.png" />
            <LandingHeroSign src="https://genius1071.friktoriaservers.net/doitforme/morning/app/vandador/landing/VandorosSign.svg" />
          </LandingHeroImg>
          <LandingHeroSplitter
            src={
              theme == "dark"
                ? "https://genius1071.friktoriaservers.net/doitforme/morning/app/vandador/landing/dark/HeaderCurve.svg"
                : "https://genius1071.friktoriaservers.net/doitforme/morning/app/vandador/landing/light/HeaderCurve.svg"
            }
          />
        </LandingHero>
      </LandingTop> */}
      <Play_Video />
        <Pricing_Plans plans={plans} />
        <Extra_Services />
      <Foot_ />
    </Landing>
  );
}
