import React, { useState } from 'react'
import styled from 'styled-components'
import { H2, IconSvg, Main_, P, device } from 'monica-alexandria'
import { useTranslation } from 'react-i18next'

const PlayVideo = styled.div`
  height: 60rem;
  background: linear-gradient(110deg, var(--main) -1%, var(--focus) 19.35%, #cea43f 60.75%);
  width: calc(100% - 8rem);
  margin: 8rem 4rem;
  border-radius: 2rem;
  box-shadow: ${p => p.theme.out};

  @media ${device.md} {
    height: auto;
    padding: 2rem;
    margin: 8rem 2rem;
    width: calc(100% - 4rem);
  }
`

const PlayVideoWrapper = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 100%;
  align-items: flex-start;
  grid-column-gap: 15rem;

  @media ${device.md} {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
`

const PlayVideoScreen = styled.div`
  height: 100%;
  aspect-ratio: 4/5;
  transform: scale(1.1);
  border-radius: 1rem;

  video{
    width: 100%;
  }

  @media ${device.md} {
    transform: scale(1);
    display: block;
    margin: 0 auto;
    height: auto;
    width: 100%;
    max-width: 50rem;
  }
`

const PlayVideoRight = styled.div`
  padding: 4rem 4rem 4rem 0;

  h2{
    color: var(--white);
  }

  ul{
    margin-top: 2rem;
    margin-bottom: 2rem;

    li{
      display: flex;
      align-items: flex-start;
      gap: 1rem;

      svg{
        width: 2.5rem;
        fill: var(--main);
        margin-top: 6px;
      }

      p{
        color: var(--white);
      }
    }
  }
`

export default function Play_Video(props) {

  const {t} = useTranslation()

  const [videoPlaying, setVideoPlaying] = useState(false);

  const toggleVideoPlay = () => {
    const video = document.getElementById('video');
    if (videoPlaying) {
      video.pause();
    } else {
      video.play();
    }
    setVideoPlaying(!videoPlaying);
  };

  return (
    <PlayVideo>
        <PlayVideoWrapper>
          <PlayVideoScreen>
              <video poster="https://genius1071.friktoriaservers.net/doitforme/morning/app/vandador/landing/VideoThumb.png" id="video" width="auto" height="100%" controls={false}>
              <source src='https://genius1071.friktoriaservers.net/doitforme/morning/app/vandador/landing/doitnow%20v2.MP4' type="video/mp4" />
              {t("Your browser does not support this video.")}
              </video>
          </PlayVideoScreen>
          <PlayVideoRight>
            <H2>{t("This video is for you")}</H2>
            <ul>
              <li>
                <IconSvg Icon="Check" />
                <P>{t("Find new customers and prospect leads")}</P>
              </li>
              <li>
                <IconSvg Icon="Check" />
                <P>{t("Increase your revenue")}</P>
              </li>
              <li>
                <IconSvg Icon="Check" />
                <P>{t("Preserve your value")}</P>
              </li>
              <li>
                <IconSvg Icon="Check" />
                <P>{t("Acquire the necessary resources")}</P>
              </li>
            </ul>
            <Main_ onClick={toggleVideoPlay} text={videoPlaying ? (t('Pause')) : (t('Play video to view how'))} iconRight={videoPlaying ? 'Bullet' : 'Next'}/>
          </PlayVideoRight>
        </PlayVideoWrapper>
    </PlayVideo>
  )
}
