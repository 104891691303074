import { H3, H5, P, Theme, ThemeContext, device } from 'monica-alexandria'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const LandingHeader = styled.div`
    margin: 2rem 4rem;

    @media ${device.md} {
        margin: 2rem;
    }
`

const LandingWrapper = styled.div`
    padding: 4rem;
    box-shadow: ${p => p.theme.outFocus};
    border-radius: 1rem;
    position: relative;
    min-height: 50rem;
    margin-top: 10rem;

    .Vandoros{
        position: absolute;
        right: 0;
        bottom: 0;
        width: 40rem;
    }

    @media ${device.lg} {
        .Vandoros{
        position: static;
        transform: translateY(43px);
        width: 100%;
        max-width: 40rem;
        margin: 0 auto;
        display: block;
        }
    }
`

const LandingLeft = styled.div`
    max-width: calc(100% - 60rem);

    h1{
        font-size: 4rem;
    }

    h5{
        text-align: right;
    }

    .Sign{
        width: 100%;
        margin-top: 2rem;
        max-width: 30rem;
    }

    @media ${device.lg} {
        max-width: none;
    }
`

export default function Landing_Header(props) {

    const {t} = useTranslation()
    const {theme} = useContext(ThemeContext);

  return (
    <LandingHeader>
        <LandingWrapper>
            <LandingLeft>
                <h1>{t("We live in an era where branding is easier, but also more necessary, than ever.")}</h1>
                <P>{t("For small and medium-sized businesses, this requires time, specialized knowledge, and outsourcing to an expert is expensive. We want to change this by providing a solution to this need in the best, most economical, but also most effective way! Welcome to the new digital age, now it's your time to take the next step! ")}</P>
                {}
                <img className='Sign' src={theme=='dark' ?
                    'https://genius1071.friktoriaservers.net/doitforme/morning/app/vandador/landing/VandorosSignDark.svg'
                    :
                    'https://genius1071.friktoriaservers.net/doitforme/morning/app/vandador/landing/VandorosSignLight.svg'
                } />
            </LandingLeft>
            <img className='Vandoros' src="https://genius1071.friktoriaservers.net/doitforme/morning/app/vandador/landing/Vandoros.png"/>
        </LandingWrapper>
    </LandingHeader>
  )
}
