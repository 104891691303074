import { Single_ } from 'monica-alexandria'
import React from 'react'
import i18n from '../../../localization/i18next';
import { useTranslation } from 'react-i18next';

const clients = require('../../../config/index').config
const authClient = process.env.REACT_APP_AUTH_CLIENT;
const lang = i18n.language ? i18n.language : 'el';

export default function NO_ACCESS() {

    const { t } = useTranslation();

    return (
        <Single_
            backgroundImage={"https://genius1071.friktoriaservers.net/doitforme/morning/app/griphook/NoAccess.svg"}
            button={t("Back to your account")}
            onClick= {()=>window.location.assign(`${clients[authClient]}/${lang}`)}
            text={t("You are not authorized to view this page")}
            title={t("Access Denied")}
        />
    )
}