import React, { useContext } from "react";
import styled from "styled-components";
import { H2, H4, H5 } from "../../../library/Presentation";
import { useTranslation } from "react-i18next";
import { IconSvg, ThemeContext, device } from "monica-alexandria";

const ExtraServices = styled.div`
  align-items: center;
  padding: 25rem 4rem 8rem 4rem;
  position: relative;
  background: var(--dark);

  h5,
  h2 {
    color: var(--white);
    text-align: center;
  }

  @media ${device.sm} {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

const ExtraServicesCurve = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  width: 100%;
`;

const ExtraServicesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 4rem;
  margin-top: 12rem;

  @media ${device.md} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${device.xs} {
    grid-template-columns: 1fr;
    grid-gap: 8rem;
  }
`;

const ExtraServicesBox = styled.div`
  text-align: center;
  cursor: pointer;

  svg {
    width: 15rem;
    fill: var(--white);
    transition: all 0.5s;
  }

  h4 {
    margin-top: 2rem;
    color: var(--white);
    height: 4rem;
  }

  aside {
    margin: 0 auto;
    margin-top: 2rem;
    max-width: 30%;
    height: 2px;
    width: 100%;
    background-color: var(--white);
    transition: all 0.5s;
  }

  &:hover {
    aside {
      max-width: 80%;
    }

    svg {
      transform: translateY(-2rem);
    }
  }
`;

export default function Extra_Services() {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <ExtraServices>
      <ExtraServicesCurve
        src={
          theme == "dark"
            ? "https://genius1071.friktoriaservers.net/doitforme/morning/app/vandador/landing/dark/ExtrasCurve.svg"
            : "https://genius1071.friktoriaservers.net/doitforme/morning/app/vandador/landing/light/ExtrasCurve.svg"
        }
      />
      <H2>{t("Extra Services")}</H2>
      <H5>{t("We are here to help your business")}</H5>
      <ExtraServicesGrid>
        <ExtraServicesBox onClick={() => window.location.assign("https://agency.doitforme.support/")}>
          <IconSvg Icon="WebsiteDev" />
          <H4>{t("Website Development")}</H4>
          <aside></aside>
        </ExtraServicesBox>
        <ExtraServicesBox onClick={() => window.location.assign("https://agency.doitforme.support/")}>
          <IconSvg Icon="EshopDev" />
          <H4>{t("Eshop Development")}</H4>
          <aside></aside>
        </ExtraServicesBox>
        <ExtraServicesBox onClick={() => window.location.assign("https://agency.doitforme.support/")}>
          <IconSvg Icon="LogoCreate" />
          <H4>{t("Logo Creation")}</H4>
          <aside></aside>
        </ExtraServicesBox>
        <ExtraServicesBox onClick={() => window.location.assign("https://agency.doitforme.support/")}>
          <IconSvg Icon="OnlinePresence" />
          <H4>{t("Basic Online Presence")}</H4>
          <aside></aside>
        </ExtraServicesBox>
      </ExtraServicesGrid>
    </ExtraServices>
  );
}
