import {
    Alert_,
    PageAlwaysVisible,
    PageForty,
    PageTitle,
    PageTools,
    Steps_,
    AlertContext,
    } from "monica-alexandria";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StepContext } from "../../context/StepContext";
import Business_Name from "./components/Business_Name";
import Business_Type from "./components/Business_Type";
import Business_Ad_Spent from "./components/Business_Ad_Spent";
import Business_Expectations from "./components/Business_Expectations";
import q from 'query-string'


  export default function QUESTIONNAIRE(props) {

    const { t } = useTranslation();
    const [step, setStep] = useState({step: 0, answers: []});
    const queryParams = q.parse(window.location.search);
    const [email, setEmail] = useState(false);
    const [plan, setPlan] = useState(false);

    useEffect(() => {
        console.log(`Entering step ${step?.step}`);
        console.log('Data: ', step.answers);
        const customer = window.sessionStorage.getItem('customer_email');
        if(!customer || queryParams?.email !== customer){
          window.sessionStorage.setItem('customer_email', queryParams?.email);
          window.sessionStorage.setItem('plan_name', queryParams?.plan);
        }
        setEmail(window.sessionStorage.getItem('customer_email'))
        setPlan(window.sessionStorage.getItem('plan_name'))
      },[])
      console.log('email', email )
    return (
        <PageTools>
        <PageForty>
            <PageAlwaysVisible>
            <PageTitle>
                <h1>{t("Fill in the questionnaire below")}</h1>
            </PageTitle>
            <StepContext.Provider value={{ step, setStep }}>
                <Steps_
                currentStep={step?.step}
                steps={[
                    <Business_Name/>,
                    <Business_Type/>,
                    <Business_Ad_Spent/>,
                    <Business_Expectations source={"LANDING_PAGE"} email={email} plan={plan}/>
                ]}
                />
            </StepContext.Provider>
            </PageAlwaysVisible>
        </PageForty>
        </PageTools>
    );
}
  