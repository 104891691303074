import styled from "styled-components";

export const H1 = styled.h1`
  font-family: "fontLight";
  line-height: 1;
`;

export const H2 = styled.h2`
  font-size: 4rem;
`;

export const H3 = styled.h3`
  font-size: 3rem;
`;

export const H4 = styled.h4`
  font-size: 2rem;
  font-family: "fontMedium";
`;

export const H5 = styled.h5`
  font-size: 2rem;
`;

export const P = styled.p`
  font-size: 2rem;
`

export const H6 = styled.h6`
  font-size: 1.6rem;
`;
