import { Builder_, Input_, Radio_, Steps_Actions } from "monica-alexandria";
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { StepContext } from "../../../context/StepContext";
import { useNavigate } from "react-router-dom";
import { push_new_answer } from "../utils";

const BusinessName = styled.div``;

export default function Business_Name() {

  const { step, setStep } = useContext(StepContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [answer, setAnswer] = useState(step?.answers[step?.step] ? step?.answers[step?.step] : {
    question: (t('What is the name of your business?')),
    type: 'text',
    answer: ''
  })

  useEffect(() => {
    console.log(`Entering step ${step?.step}`);
    console.log('Data: ', step.answers);
  },[])

  return (
    <BusinessName>
      <Builder_ title={answer.question}>
        <Input_
          placeholder={t("Business name")}
          defaultValue={answer?.answer}
          onChange={(e) => setAnswer({...answer, type: e.target.type, answer: e.target.value})}
        />
      </Builder_>
      <Steps_Actions
        icon_Grey="Xicon"
        icon_Main="Next"
        onClick_Grey={() => navigate(-1)}
        onClick_Main={() => setStep({step: step?.step + 1, answers: push_new_answer(step, answer)})}
        text_Grey={t("Exit")}
        text_Main={t("Next")}
      />
    </BusinessName>
  );
}
