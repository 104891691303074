import {
  IconSvg,
  Main_,
  Main_Plain,
  PageAlwaysVisible,
  PageDesktopOnly,
  PageDesktopPadding,
  PageForty,
  PageIllu,
  PageTitle,
  Section_Title,
} from "monica-alexandria";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../localization/i18next";
import styled from "styled-components";

const AppointmentPara = styled.p`
  margin-top: 1rem;
`;

const AppointmentHeader = styled.h3`
  margin-top: 2rem;
`;

const AppointmentSteps = styled.ul`
  li {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin-top: 2rem;

    aside {
      svg {
        width: 1rem;
        margin-top: 0.5rem;
      }
    }

    button {
      margin-left: 2rem;
    }
  }
`;

const AppointmentFooter = styled.h6`
  margin-top: 6rem;
  margin-bottom: 2rem;
`;

export default function YOUR_APPOINTMENT(props) {
  const { t } = useTranslation();

  return (
    <PageForty>
      <PageAlwaysVisible>
        <PageTitle>
          <h1>{t("Book an appointment")}</h1>
        </PageTitle>
        <AppointmentHeader>{t("Congratulations")}!</AppointmentHeader>
        <AppointmentPara>
          {t("You've successfully enrolled in our subscription plan and completed the preliminary questionnaire. We appreciate the trust you've placed in us and are excited to get started on this journey with you")}
          .
        </AppointmentPara>
        <AppointmentPara>
          {t("To ensure a productive meeting, please set up a one-on-one appointment with our marketing team. This is an opportunity for us to discuss the insights from your questionnaire, understand your unique needs, and outline the next steps tailored for you")}.
        </AppointmentPara>
        <Section_Title text={t("Preparation Checklist")} />
        <AppointmentSteps>
          <li>
            <aside>
              <IconSvg Icon="Bullet" />
            </aside>
            <div>
              <h5>{t("AnyDesk Installation")}</h5>
              <p>
                {t("Our remote setup will be facilitated via AnyDesk. Please install this software on your computer prior to our meeting if it's not already present")}
                .
              </p>
            </div>
          </li>
          <li>
            <Main_Plain
              text={t("Download Anydesk here")}
              noPadding
              onClick={() => window.open("https://anydesk.com/en/", '_blank')}
            />
          </li>
          <li>
            <aside>
              <IconSvg Icon="Bullet" />
            </aside>
            <div>
              <h5>{t("Google Account Credentials")}</h5>
              <p>
                {t("Have your Google (Gmail) account login credentials ready. We may need them for various integrations")}.
              </p>
            </div>
          </li>
          <li>
            <aside>
              <IconSvg Icon="Bullet" />
            </aside>
            <div>
              <h5>{t("Payment Card Details")}</h5>
              <p>
                {t("Please keep handy the credit/debit card details that you intend to use for advertising payments")}.
              </p>
            </div>
          </li>
          <li>
            <aside>
              <IconSvg Icon="Bullet" />
            </aside>
            <div>
              <h5>{t("Social Media Access")}</h5>
              <p>
                {t("We will require access to your business's social media profiles. Ensure you're logged into the Facebook profile that created your business page, as we may need to review or integrate tools with it")}.
              </p>
            </div>
          </li>
          <li>
            <aside>
              <IconSvg Icon="Bullet" />
            </aside>
            <div>
              <h5>{t("Website Admin Credentials")}</h5>
              <p>
                {t("Have available the login credentials for your website's administrative panel or dashboard")}.
              </p>
            </div>
          </li>
          <li>
            <aside>
              <IconSvg Icon="Bullet" />
            </aside>
            <div>
              <h5>{t("Stable Internet & Workspace")}</h5>
              <p>
                {t("Ensure a consistent internet connection and a quiet environment to avoid interruptions")}.
              </p>
            </div>
          </li>
          <li>
            <aside>
              <IconSvg Icon="Bullet" />
            </aside>
            <div>
              <h5>{t("Functional Webcam & Microphone")}</h5>
              <p>
                {t("These tools are essential for effective communication during our setup session")}.
              </p>
            </div>
          </li>
        </AppointmentSteps>
        <Section_Title text={t("Book your session")} />
        <AppointmentSteps>
          <li>
            <aside>
              <IconSvg Icon="Bullet" />
            </aside>
            <h5>{t("Click on the button below to view available slots.")}</h5>
          </li>
          <li>
            <Main_
              size="large"
              text={t("Setup your appointment")}
              iconRight="Calendar"
              onClick={() =>
                window.location.assign(
                  "https://calendly.com/digitalmarketing-w0/15min"
                )
              }
            />
          </li>
          <li>
            <aside>
              <IconSvg Icon="Bullet" />
            </aside>
            <h5>{t("Select a time that works best for you.")}</h5>
          </li>
          <li>
            <aside>
              <IconSvg Icon="Bullet" />
            </aside>
            <h5>
              {t("Confirm the appointment and mark the date on your calendar")}.
            </h5>
          </li>
        </AppointmentSteps>
        <AppointmentFooter>
          <b>{t("Remember")}:</b>{" "}
          {t("Our meeting is about establishing a solid foundation for our partnership. With your cooperation in having the above information and tools ready, we can dive right into action, saving time and focusing on what truly matters - your business's success")}.
        </AppointmentFooter>
        <h6>
          {t("Thank you for choosing us. We eagerly await our collaboration")}!
        </h6>
      </PageAlwaysVisible>
      <PageDesktopOnly>
        <PageDesktopPadding>
          <PageIllu>
            <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/vandador/appointment/CupTalk.svg" />
          </PageIllu>
        </PageDesktopPadding>
      </PageDesktopOnly>
    </PageForty>
  );
}
