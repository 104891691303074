import { Builder_, Radio_, Steps_Actions } from "monica-alexandria";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { StepContext } from "../../../context/StepContext";
import { push_new_answer } from "../utils";

const BusinessAdSpent = styled.div``;

export default function Business_Ad_Spent(props) {

  const { step, setStep } = useContext(StepContext);
  const { t } = useTranslation();
 
  const [answer, setAnswer] = useState(step?.answers[step?.step] ? step?.answers[step?.step] : {
    question: (t('What is your estimated monthly expenditure for advertising campaigns in social media?')),
    type: 'radio',
    answer: '<300'
  })

  useEffect(() => {
    console.log(`Entering step ${step?.step}`);
    console.log('Data: ', step.answers);
  },[])

  return (
    <BusinessAdSpent>
      <Builder_ title={answer.question}>
        <Radio_
          id="<300"
          text={t('Under 300')}
          value="<300"
          checked={answer.answer == "<300"}
          onChange={(e) => setAnswer({...answer, type: e.target.type, answer: e.target.value})}
        />
        <Radio_
          id="300-1000"
          text={t('Between 300 and 1000')}
          value="Between 300 and 1000"
          checked={answer.answer == "Between 300 and 1000"}
          onChange={(e) => setAnswer({...answer, type: e.target.type, answer: e.target.value})}
        />
        <Radio_
          id=">1000"
          text={t('Over 1000')}
          value="Over 1000"
          checked={answer.answer == 'Over 1000'}
          onChange={(e) => setAnswer({...answer, type: e.target.type, answer: e.target.value})}
        />
        <Steps_Actions
          icon_Grey="Back"
          icon_Main="Next"
          onClick_Grey={() => setStep({...step, step: step?.step - 1})}
          onClick_Main={() => setStep({step: step?.step + 1,  answers: push_new_answer(step, answer)})}
          text_Grey={t("Back")}
          text_Main={t("Next")}
        />
      </Builder_>
    </BusinessAdSpent>
  );
}
