import { Card, CardsFull, CardsSixty, Grey_, IconSvg, On_Click_Card, PageFull, PageTitle, Section_Title, Slider_ } from 'monica-alexandria'
import React, { useState } from 'react'
import styled from 'styled-components'

const Leads = styled(PageFull)``

const LeadsStatus = styled.ul`
    li{
        display: flex;
        align-items: center;
        gap: 1rem;

        svg{
            width: var(--smallIcon);
            fill: var(--greyDark);
        }
    }
`

// TODO: COMPONENT
const Lead = styled.div`
    ul{

        li{
            display: grid;
            grid-template-columns: 1fr 1fr;
            border-bottom: 1px dashed var(--greyDark);
            margin-bottom: 1rem;
            padding-bottom: 1rem;

            &:last-of-type{
                border-bottom: none;
                padding-bottom: 0;
            }
        }
    }
`


const LeadForms = styled.div``

const LeadForm = styled(Card)`
    padding: var(--normalPads);

    h3{
        margin-bottom: 2rem;
    }

    ul{

        li{
            display: grid;
            grid-template-columns: 1fr 1fr;
            border-bottom: 1px dashed var(--greyDark);
            margin-bottom: 1rem;
            padding-bottom: 1rem;

            &:last-of-type{
                border-bottom: none;
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
    }
`

export default function LEADS() {

    const [slider, setSlider] = useState(true)

    const users = [
        {   "email" : "user.email.eu",
            "status" : "not registered",
            "plan": "basic"
        },
        {   "email" : "user.email.eu",
            "status" : "registered",
            "plan": "standard"
        },
        {   "email" : "user.email.eu",
            "status" : "registered",
            "plan": "premium"
        }
]


  return (
    <Leads>
        {slider ? 
            <Slider_ title="Lead">
                <Lead>
                    <ul>
                        <li>
                            <h5>Email address:</h5>
                            <p>user@email.com</p>
                        </li>
                        <li>
                            <h5>User status:</h5>
                            <p>Regitered</p>
                        </li>
                    </ul>
                </Lead>
                <Section_Title text="Forms" />
                <LeadForms>
                    <LeadForm>
                        <h3>Standard Plan</h3>
                        <ul>
                            <li>
                                <h5>Business name:</h5>
                                <p>Loukas</p>
                            </li>
                            <li>
                                <h5>Kind of business:</h5>
                                <p>Products</p>
                            </li>
                            <li>
                                <h5>Budget:</h5>
                                <p>Under 300</p>
                            </li>
                            <li>
                                <h5>Expectations:</h5>
                                <p>Increase recognition</p>
                            </li>
                        </ul>
                    </LeadForm>
                </LeadForms>
            </Slider_>
        : null}
        <PageTitle>
            <Grey_ iconLeft="Back" />
            <h1>Leads</h1>
        </PageTitle>
        <CardsFull>
            {users?.map((item, i)  =>{
            return <On_Click_Card key={i}
                    subtitle={item?.email}
                >
                    <LeadsStatus>
                        <li>
                            <IconSvg Icon="Bullet" />
                            <h6>{item?.status}</h6>
                        </li>
                    </LeadsStatus>
                </On_Click_Card>
            })
            }
        </CardsFull>
    </Leads>
  )
}
