import { CardsTools, On_Click_Card, PageTitle, PageTools } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Toolbar_ from '../../partials/navigation/Toolbar_'

export default function PANEL() {
  const navigate = useNavigate();
  const handlenav = (toPath) => {
    navigate(toPath);
  }
  const {t} = useTranslation();
  return (
    <PageTools>
        <Toolbar_ />
        <PageTitle>
            <h1>{t("Vandoros's admin panel")}</h1>
        </PageTitle>
        <CardsTools>
          <On_Click_Card
            onClick = {()=>handlenav('translations')}
            icon = 'Flag'
            title = {t('Translations')}
          />
          <On_Click_Card 
            onClick = {()=>handlenav('leads')}
            icon = 'User'
            title = {t('Leads')}
          />
        </CardsTools>
    </PageTools>
  )
}
