import { Alert_, Builder_, Radio_, Steps_Actions } from "monica-alexandria";
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { StepContext } from "../../../context/StepContext";
import { useNavigate, useParams } from "react-router-dom";
import i18n from '../../../localization/i18next';
import { push_new_answer } from "../utils";
import { create_form } from "../../../services/api/Forms";
import { AlertContext } from "monica-alexandria";
import { alertMessage } from "../../../utils/alerts";

const BusinessExpectations = styled.div``;

export default function Business_Expectations(props) {
  
  const { step, setStep } = useContext(StepContext);
  const { alerts, setAlerts } = useContext(AlertContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const lang = i18n.language ? i18n.language : 'el';

  const [answer, setAnswer] = useState(step?.answers[step?.step] ? step?.answers[step?.step] : {
    question: (t("What do you expect from us?")),
    type: 'radio',
    answer: 'Increase recognition'
  })

  const submit_data_and_exit_questionnaire = () => {
    
    let finalAnswers = push_new_answer(step, answer);

    let payload = {
      app: 'vandador',
      source: props?.source ? props.source : 'NO_SOURCE_GIVEN',
      lang: params?.lang,
      answers: finalAnswers,
      email: props?.email ? props.email: 'george.chalvatzis@gmail.com',
      plan: props?.plan ? props.plan: ''
    }

    console.log(payload);
 
    create_form(payload, t).then(() => {
      navigate(`/${lang}/your-appointment`);
    }).catch(error => {
      alertMessage(alerts, setAlerts, error, 'Error');
    })
  }

  useEffect(() => {
    console.log(`Entering step ${step?.step}`);
    console.log('Data: ', step.answers);
  },[])

  return (
    <BusinessExpectations>
      <Alert_ messages={alerts} duration={6000}/>
      <Builder_ title={answer.question}>
        <Radio_
          id="recognition"
          text={t('Increase recognition')}
          value="Increase recognition"
          checked={answer?.answer == "Increase recognition"}
          onChange={(e) => setAnswer({...answer, type: e.target.type, answer: e.target.value})}
        />
        <Radio_
          id="sales"
          text={t('Increase in sales')}
          value="Increase in sales"
          checked={answer?.answer == "Increase in sales"}
          onChange={(e) => setAnswer({...answer, type: e.target.type, answer: e.target.value})}
        />
        <Radio_
          id="time"
          text={t('I just exchange time for you to upload my posts')}
          value="I just exchange time for you to upload my posts"
          checked={answer?.answer == "I just exchange time for you to upload my posts"}
          onChange={(e) => setAnswer({...answer, type: e.target.type, answer: e.target.value})}
        />
      </Builder_>
      <Steps_Actions
          icon_Grey="Back"
          icon_Main="Next"
          onClick_Grey={() => setStep({...step, step: step?.step - 1})}
          onClick_Main={() => submit_data_and_exit_questionnaire()}
          text_Grey={t("Back")}
          text_Main={t("Next")}
        />
    </BusinessExpectations>
  );
}
