import { Footer_ } from "monica-alexandria";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../localization/i18next";

const fahed_url = process.env.REACT_APP_FAHED_FRONTEND_PROTOCOL +
                  process.env.REACT_APP_FAHED_FRONTEND_HOST +
                  process.env.REACT_APP_FAHED_FRONTEND_PORT

export default function Foot_() {
  const { t } = useTranslation();

  return (
    <Footer_
      sales={t("Sales Department")}
      development={t("Development Department")}
      marketing={t("Marketing Department")}
      follow={t("Follow us")}
      headquarters={t("Headquarters")}
      address={t("Mikrasiaton 75, Volos, Greece")}
      terms={<a href={`${fahed_url}/${i18n?.language}/terms-and-conditions`}>{t("Terms and Conditions")}</a>}
      privacy={<a href={`${fahed_url}/${i18n?.language}/data-protection-policy`}>{t("Privacy Policy")}</a>}
    />
  );
}
