import { Sidebar_, Sidebar_Box } from 'monica-alexandria'
import React from 'react'

export default function Toolbar_() {
  return (
    <Sidebar_>
        <Sidebar_Box 
            text = 'Translations'
            icon = 'Region'
        />
    </Sidebar_>
  )
}
