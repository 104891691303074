import {
  CardsSixty,
  On_Click_Card,
  ThemeContext,
  device,
  logo,
} from "monica-alexandria";
import React, { useContext, useState } from "react";
import { Focus_, IconSvg } from "monica-alexandria";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { H1, H2, H3, H4, H5, H6 } from "../../../library/Presentation";
import { prettifyPrice } from "../../../helpers/prettifyFunctions";
import { config } from "../../../config";
import { useParams } from "react-router-dom";

const PricingPlans = styled.div`
  background: ${(p) => p.theme.background};
`;

const PricingPlansWrapper = styled.div`
  padding: 8rem 4rem;

  h2,
  h5 {
    text-align: center;
  }

  @media ${device.sm} {
    padding: 8rem 2rem;
  }
`;

const PricingPlansGrid = styled.div`
  display: grid;
  margin-top: 8rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 4rem;
  justify-content: space-between;

  @media ${device.lg} {
    grid-template-columns: 1fr;
  }
`;

const PiricingPlansCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${(p) => p.theme.low};
  padding: 4rem 4rem 6rem 4rem;
  border: 2px solid ${(p) => p.theme.pure};
  border-radius: var(--normalRadius);
  box-shadow: ${(p) =>
    p.highlight ? "0 0 15px 2px var(--focus)" : "0 0 15px 2px var(--light)"};

  h3 {
    text-align: center;
    margin-top: 2rem;
  }
`;

const PricingPlanPrice = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;

  h1 {
    color: var(--focus);
    font-size: 3.5rem !important;
  }
`;

const PricingPlanList = styled.ul`
  max-width: 35rem;
  margin: 0 auto;

  li {
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: flex-start;
    grid-column-gap: 1rem;
    margin-top: 2rem;

    svg {
      width: 1rem;
      margin-top: 0.5rem;
      fill: ${(p) => p.theme.color};
    }

    H6 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
      color: var(--greyDark);
    }
  }
`;

const PricingPlanActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;

  @media ${device.lg} {
    button {
      width: 100%;
      justify-content: center;
    }
  }
`;

export default function Pricing_Plans(props) {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();

  const { lang } = useParams();

  const planDescriptions = {
    "Basic Plan": [
      { title: (t("1 Post per week")), subtitle: (t("Facebook & Instagram")) },
      { title: (t("1 Story per week")) },
      { title: (t("1 Meta Ads Campaign")), subtitle: (t("Facebook & Instagram")) },
      { title: (t("Google my Business")), subtitle: (t("Setup or Optimization")) },
    ],

    "Standard Plan": [
      { title: (t("2 Posts per week")), subtitle: (t("Facebook & Instagram")) },
      { title: (t("3 Stories per week")) },
      {
        title: (t("3 Meta Ads Campaigns")),
        subtitle: (t("Sales Funnel Facebook & Instagram")),
      },
      {
        title: (t("Meta Ads Campaign Monitoring")),
      },
      { title: (t("Google my Business")), subtitle: (t("Setup or Optimization")) },
      { title: (t("Google Ads")), subtitle: (t("Basic Setup")) },
    ],

    "Premium Plan": [
      { title: (t("3 Posts per week")), subtitle: (t("Facebook & Instagram")) },
      { title: (t("4 Stories per week")) },
      {
        title: (t("3 Meta Ads Campaigns")),
        subtitle: (t("Sales Funnel Facebook & Instagram")),
      },
      {
        title: (t("Meta Ads Campaign Monitoring")),
      },
      { title: (t("Google my Business")), subtitle: (t("Setup or Optimization")) },
      {
        title: (t("Google Ads")),
        subtitle: (t("Constant Monitoring and Optimisation")),
      },
      { title: (t("Email Marketing")), subtitle: (t("2 Emails per Month")) },
      {
        title: (t("Personal Chat with Dedicated Marketing Team")),
        subtitle: (t("From 9 AM to 5PM")),
      },
    ],
  };

  function prettifyCurrency(currency) {
    if (currency == "eur" || !currency) return "€";
    else return currency;
  }

  const handleClick = (item) => {
    window?.location?.assign(
      `${config.griphook}/${lang}/checkout-details-av?productId=${item}`
    );
  };

  console.log(props?.plans);
  return (
    <PricingPlans onClick={() => handleClick}>
      <PricingPlansWrapper>
        <H2>{t("Subscription Plans")}</H2>
        <H5>{t("Find the plan that suits your needs")}</H5>
        <PricingPlansGrid>
        {props?.plans?.length>0 ? props?.plans
          ?.sort((planA, planB) => planA.amount - planB.amount)
          .map((plan, i) => (
            <PiricingPlansCard
              key={i}
              highlight={i === Math.floor(props?.plans?.length / 2)}
            >
              <div>
                <H3>{plan?.name?.toUpperCase()}</H3>
                <PricingPlanPrice>
                  <h1>{`${prettifyCurrency(plan?.currency)} ${prettifyPrice(
                    plan?.amount
                  )}`}</h1>
                  <p> / {plan?.interval == 'month' ? (t("month")): (t("year"))}</p>
                </PricingPlanPrice>
                <PricingPlanList>
                  {planDescriptions[plan?.name]?.map((feature, i) => (
                    <li key={i}>
                      <IconSvg Icon="Bullet" />
                      <H4>{feature?.title}</H4>
                      {feature?.subtitle && <H6>{feature?.subtitle}</H6>}
                    </li>
                  ))}
                </PricingPlanList>
              </div>
              <PricingPlanActions>
                <Focus_
                  text={t("SUBSCRIBE")}
                  size="large"
                  iconRight="Next"
                  onClick={() => handleClick(plan?.productId)}
                />
              </PricingPlanActions>
            </PiricingPlansCard>
          )): null}
          {/* <PiricingPlansCard>
            <div>
              <H3>BASIC PLAN</H3>
              <PricingPlanPrice>
                <h1>€ 120,00</h1>
                <p> / month</p>
              </PricingPlanPrice>
              <PricingPlanList>
                <li>
                  <IconSvg Icon="Bullet" />
                  <H4>1 Post per week</H4>
                  <H6>Facebook & Instagram</H6>
                </li>
                <li>
                  <IconSvg Icon="Bullet" />
                  <H4>1 Story per week</H4>
                </li>
                <li>
                  <IconSvg Icon="Bullet" />
                  <H4>1 Meta Ads Campaign</H4>
                  <H6>Facebook & Instagram</H6>
                </li>
                <li>
                  <IconSvg Icon="Bullet" />
                  <H4>Google my Business</H4>
                  <H6>Setup or Optimisation</H6>
                </li>
              </PricingPlanList>
            </div>
            <PricingPlanActions>
              <Focus_ size="large" text={(t("SUBSCRIBE")} iconRight="Next" />
            </PricingPlanActions>
          </PiricingPlansCard>
          <PiricingPlansCard highlight>
            <div>
              <H3>STANDARD PLAN</H3>
              <PricingPlanPrice>
                <h1>€ 300,00</h1>
                <p> / month</p>
              </PricingPlanPrice>
              <PricingPlanList>
                <li>
                  <IconSvg Icon="Bullet" />
                  <H4>2 Post per week</H4>
                  <H6>Facebook & Instagram</H6>
                </li>
                <li>
                  <IconSvg Icon="Bullet" />
                  <H4>3 Story per week</H4>
                </li>
                <li>
                  <IconSvg Icon="Bullet" />
                  <H4>Meta Ads Campaign Monitoring</H4>
                  <H6>
                    Sales Funnel <br /> Facebook & Instagram
                  </H6>
                </li>
                <li>
                  <IconSvg Icon="Bullet" />
                  <H4>Google my Business</H4>
                  <H6>Setup or Optimisation</H6>
                </li>
                <li>
                  <IconSvg Icon="Bullet" />
                  <H4>Google Ads</H4>
                  <H6>Basic Setup</H6>
                </li>
              </PricingPlanList>
            </div>
            <PricingPlanActions>
              <Focus_ size="large" text={(t("SUBSCRIBE")} iconRight="Next" />
            </PricingPlanActions>
          </PiricingPlansCard>
          <PiricingPlansCard>
            <div>
              <H3>PREMIUM PLAN</H3>
              <PricingPlanPrice>
                <h1>€ 500,00</h1>
                <p> / month</p>
              </PricingPlanPrice>
              <PricingPlanList>
                <li>
                  <IconSvg Icon="Bullet" />
                  <H4>3 Post per week</H4>
                  <H6>Facebook & Instagram</H6>
                </li>
                <li>
                  <IconSvg Icon="Bullet" />
                  <H4>4 Story per week</H4>
                </li>
                <li>
                  <IconSvg Icon="Bullet" />
                  <H4>Meta Ads Campaign Monitoring</H4>
                  <H6>
                    Sales Funnel <br /> Facebook & Instagram
                  </H6>
                </li>
                <li>
                  <IconSvg Icon="Bullet" />
                  <H4>Google my Business</H4>
                  <H6>Setup or Optimisation</H6>
                </li>
                <li>
                  <IconSvg Icon="Bullet" />
                  <H4>Google Ads</H4>
                </li>
                <li>
                  <IconSvg Icon="Bullet" />
                  <H4>Email Marketing</H4>
                  <H6>2 Emails per Month</H6>
                </li>
                <li>
                  <IconSvg Icon="Bullet" />
                  <H4>Personal Chat with Dedicated Marketing Team</H4>
                  <H6>From 9:00 to 17:00</H6>
                </li>
              </PricingPlanList>
            </div>
            <PricingPlanActions>
              <Focus_ size="large" text={(t("SUBSCRIBE")} iconRight="Next" />
            </PricingPlanActions>
          </PiricingPlansCard> */}
        </PricingPlansGrid>
      </PricingPlansWrapper>
    </PricingPlans>
  );
}
