

// If this answer was already submitted, don't push it but edit it.
export const push_new_answer = (step, answer) => {

    let newAnswers = JSON.parse(JSON.stringify(step));
    newAnswers = newAnswers.answers;

    if (step?.answers?.length == step.step) newAnswers.push(answer);
    else newAnswers[step?.step] = answer;

    return newAnswers;
  }