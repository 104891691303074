import axios from "axios"
import { config } from "../../config"


const create_form = (payload, t) => {

    return new Promise((resolve, reject) => {

        axios.post(`${config.VANDADOR_BACKEND_URL}/forms/createForm`, {
            data: payload
        })
        .then(res => {
            console.log('[createForm] >',res.data);
            if ([4000, 4001].includes(res?.data?.code)) return reject(t('Something went wrong while submitting your form'));
            return resolve(res)
        })
        .catch(() => {
            return reject('createForm');
        })
    })
}


export {
    create_form
};
